import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'
import ButtonOrangeBigBorder from 'components/elements/ButtonOrangeBigBorder'

const StyledImageLinks = styled.section``

const ImageLinksLink = styled(motion.div)`
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1200px) {
    width: 225px;
    height: 225px;
  }

  @media (max-width: 1199px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 991px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 400px) {
    width: 140px;
    height: 140px;
  }
`

const ImageLinksLinkImage = styled(Image)`
  @media (min-width: 992px) {
    max-width: 50%;
    height: 110px;
  }

  @media (max-width: 991px) {
    max-width: 40%;
    height: 60px;
  }

  @media (max-width: 575px) {
    margin-top: 5px;
    max-width: 35%;
    height: 55px;
  }
`

const ImageLinksLinkTitle = styled.span`
  color: ${({ theme }) => theme.color.text.purple};
  height: 45px;
  display: block;
  text-align: center;
  word-break: break-word;

  @media (min-width: 992px) {
    font-weight: ${({ theme }) => theme.font.weight.l};
    text-transform: uppercase;
  }

  @media (min-width: 576px) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }

  @media (max-width: 575px) {
    font-size: 13px;
  }
`

interface ImageLinksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ImageLinks
}

const ImageLinks: React.FC<ImageLinksProps> = ({ fields }) => (
  <StyledImageLinks className="my-1 my-lg-5">
    <div className="container py-5">
      <ParseContent content={fields?.description} />
      <div className="row mt-5">
        {fields?.links?.map((edge, index: number) => (
          <div
            className={`col-6 col-sm-4 col-md-3 mb-5 d-flex justify-content-md-center justify-content-${
              index % 2 === 0 ? 'end' : 'start'
            }`}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Link to={edge?.link?.url || ''}>
              <ImageLinksLink
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>
                  <span className="d-block w-100 text-center">
                    <ImageLinksLinkImage
                      image={edge?.image}
                      alt={edge?.link?.title}
                    />
                  </span>
                  <ImageLinksLinkTitle className="px-2 px-lg-3 mt-3 mt-sm-4">
                    {edge?.link?.title}
                  </ImageLinksLinkTitle>
                </span>
              </ImageLinksLink>
            </Link>
          </div>
        ))}
      </div>
      <div className="text-center">
        <ButtonOrangeBigBorder to={fields?.link?.url || ''}>
          {fields.link?.title}
        </ButtonOrangeBigBorder>
      </div>
    </div>
  </StyledImageLinks>
)

export default ImageLinks
